exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ares-station-js": () => import("./../../../src/pages/ares-station.js" /* webpackChunkName: "component---src-pages-ares-station-js" */),
  "component---src-pages-astroland-js": () => import("./../../../src/pages/astroland.js" /* webpackChunkName: "component---src-pages-astroland-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigacion-js": () => import("./../../../src/pages/investigacion.js" /* webpackChunkName: "component---src-pages-investigacion-js" */),
  "component---src-pages-misiones-js": () => import("./../../../src/pages/misiones.js" /* webpackChunkName: "component---src-pages-misiones-js" */),
  "component---src-pages-programas-js": () => import("./../../../src/pages/programas.js" /* webpackChunkName: "component---src-pages-programas-js" */),
  "component---src-pages-satellites-js": () => import("./../../../src/pages/satellites.js" /* webpackChunkName: "component---src-pages-satellites-js" */),
  "component---src-pages-space-center-js": () => import("./../../../src/pages/space-center.js" /* webpackChunkName: "component---src-pages-space-center-js" */)
}

